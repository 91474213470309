.trigger {
  padding: 15px 0;
  font-size: 18px;
  cursor: pointer;
  color: white;
  display: flex;
  transition: color 0.3s;
  justify-content: center;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout {
  background: #fff;
}
.site-layout > * {
  padding: 1rem;
}