.resources-form {
    
    /* margin: 0 auto; */
    padding-left: 3rem;
}
.add-resources {
    display: flex;
    
}
.add-resources-or {
    align-self: center;
    padding: 0 2rem
}

.upload-json {
    align-self: center;
    padding: 0 2rem;
    flex: auto;
}

.resources-form-div {
    flex: auto;
}

.heading {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
}